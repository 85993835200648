export const useNumbers = () => {
  const navigatorLanguage = window.navigator.language;
  const currency = 'EUR';

  const formatNumberToIsoNumber = (number: number) => {
    return new Intl.NumberFormat(navigatorLanguage, {
      maximumFractionDigits: 0,
    }).format(number);
  };

  const formatNumberToIsoEuroNumber = (number: number) => {
    return new Intl.NumberFormat(navigatorLanguage, {
      style: 'currency',
      currency: currency,
      maximumFractionDigits: 0,
    }).format(number);
  };

  return { formatNumberToIsoNumber, formatNumberToIsoEuroNumber };
};
